<template>
	<div style="height: 100%">
		<table-company-brief
			:isAdmin="isAdmin"
			:briefs="lstBriefs"
			:isLoading="isLoading"
			:refreshData="refreshData"
			title="Company Brief"
			@setLoading="setLoading"
			@addBrief="addBrief"
			@removeBrief="removeBrief"
			@updateBrief="updateBrief"
		/>
	</div>
</template>

<script>
import { getBriefs } from "./../services/services";
import { TableCompanyBrief } from "@advise/styleguide";

export default {
	components: {
		TableCompanyBrief,
	},
	data() {
		return {
			isLoading: false,
			lstBriefs: [],
			isAdmin: false,
			isScreensizeLess: false,
		};
	},
	methods: {
		async refreshData() {
			this.isLoading = true;
			this.lstBriefs = await getBriefs();
			this.isLoading = false;
		},
		setLoading(value) {
			this.isLoading = value;
		},
		addBrief(brief) {
			this.lstBriefs.push(brief);
		},
		removeBrief(id) {
			this.lstBriefs = [...this.lstBriefs.filter((brief) => brief.id !== id)];
		},
		updateBrief(brief) {
			const index = this.lstBriefs.findIndex((b) => b.id === brief.id);
			if (index !== -1) {
				this.lstBriefs.splice(index, 1, brief);
			}
		},
	},
	async created() {
		await this.refreshData();
	},
};
</script>

<style scoped>
.container-tab-item {
	background-color: unset;
}
.get-help-container {
	max-width: 1400px !important;
}

.rounded {
	width: 30px;
	height: 30px;
	line-height: 30px;
	border-radius: 50% !important;
	text-align: center;
	display: inline-block;
	margin-right: 15px;
	background-color: #f3f4f8;
}

#active-tab--first:not(.active-tab) {
	background: #00a5ff !important;
	color: white !important;
}
#active-tab--first:not(.active-tab) .rounded {
	background-color: white;
	color: #00a5ff;
}

.company-brief {
	clip-path: polygon(0% 0%, 96% 0%, 100% 50%, 96% 100%, 0% 100%);
	margin-left: -20px;
	left: 10px;
	padding-left: 30px;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1) !important;
	font-family: "Oxygen";
	letter-spacing: 0px;
}

.goals-definition {
	clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 4% 50%, 0% 0%);
	right: 10px;
	margin-right: -20px;
	letter-spacing: 0px;
}

/* .company-brief {
  
}

.goals-definition {
  width: 50%;
  margin-left: 0.8rem;
} */

.get-help-tab {
	z-index: 4;
	text-transform: none;
	font-weight: bold;
	box-shadow: 0px 3px 5px rgb(182, 181, 181);
	background-color: #fff;
}
.get-help-tab:not(.v-tab--active) {
	color: #2c3852 !important;
	opacity: 1 !important;
}
.active-tab {
	background-color: #00a5ff;
	color: white !important;
}

.active-tab .rounded {
	background-color: #f3f4f7;
	color: #00a5ff;
	font-family: "Oxygen";
	font-style: normal;
	font-weight: 700;
	font-size: 12.5px;
}
.goals {
	margin-left: 18px;
}

.container-triangle {
	width: 50%;
	height: 80%;
	box-shadow: 0px 4px 5px rgb(0 0 0 / 10%);
}
</style>
